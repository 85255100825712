<template>
  <div class="stepsdemo-content">
    <Toast position="top-right" />
    <p>Renseigner les informations complémentaires sur vous.</p>

    <div class="p-grid">
      <div class="p-col-12">
        <small v-if="getForm().$invalid && submitted" style="color: red">
          Tous les champs avec une étoile (<span class="p-text-bold text-red">*</span>) sont obligatoires.
        </small>
        <br />
        <div class="card">
          <h5>Mon Profil</h5>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-3">
              <label for="first-Name-id">Nom <span class="p-text-bold text-red">*</span></label>
              <InputText
                type="text"
                id="last-name-id"
                v-model="getField('lastName').$model"
                :class="{ 'p-invalid': hasAnyErrors('lastName') && submitted }"
              />
              <small v-show="hasRequiredError('lastName') && submitted" style="color: red"
                >Veuillez renseigner le nom.</small
              >
              <small v-show="hasMinLenghtError('lastName') && submitted" style="color: red">
                Le nom doit contenir au minimum 2 charactères.
              </small>
            </div>
            <div class="p-field p-col-12 p-md-3">
              <label for="first-name-id">Prénom <span class="p-text-bold text-red">*</span></label>

              <InputText
                v-model="getField('firstName').$model"
                id="first-Name-id"
                type="text"
                :class="{ 'p-invalid': hasAnyErrors('firstName') && submitted }"
              />
              <small v-show="hasRequiredError('firstName') && submitted" style="color: red">
                Veuillez renseigner le prénom.
              </small>
              <small v-show="hasMinLenghtError('firstName') && submitted" style="color: red">
                Le nom doit contenir au minimum 2 charactères.
              </small>
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label for="mobile-id">Téléphone 1 <span class="p-text-bold text-red">*</span></label>
              <InputText
                v-model="getField('mobile').$model"
                id="mobile-id"
                type="number"
                class="no-arrow"
                :class="{ 'p-invalid': hasAnyErrors('mobile') && submitted }"
              />
              <small v-show="hasRequiredError('mobile') && submitted" style="color: red"
                >Veuillez renseigner le numéro de téléphone.</small
              >
              <small v-show="hasNumericError('mobile') && submitted" style="color: red">
                Le nom doit contenir uniquement des caractères numériques.
              </small>
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label for="email">E-mail <span class="p-text-bold text-red">*</span></label>
              <InputText :disabled="true" v-model="payload.email" id="email" type="text" />
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label for="fonction">Fonction dans l'entreprise <span class="p-text-bold text-red">*</span></label>
              <InputText
                v-model="getField('positionDescription').$model"
                id="fonction-id"
                type="text"
                :class="{ 'p-invalid': hasAnyErrors('positionDescription') && submitted }"
              />
              <small v-show="hasRequiredError('positionDescription') && submitted" style="color: red">
                Veuillez renseigner la fonction que vous occupez.
              </small>
              <small v-show="hasMinLenghtError('positionDescription') && submitted" style="color: red">
                Le nom doit contenir au minimum 2 charactères.
              </small>
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label for="fixe">Téléphone 2</label>
              <InputText
                v-model="getField('phone').$model"
                id="phone-id"
                class="no-arrow"
                type="number"
                :class="{ 'p-invalid': hasRequiredError('phone') && submitted }"
              />
              <p v-show="hasNumericError('phone') && submitted" style="color: red">
                Le nom doit contenir uniquement des caractères numériques.
              </p>
            </div>
          </div>
          <br />

          <Button v-bind:style="{ width: '25%' }" @click="handlePreviousStep" label="Retour" class="p-mr-2 p-mb-2" />

          <Button
            v-show="canPressSubmit"
            @click="handleSubmit"
            v-bind:style="{ width: '25%' }"
            label="Enregistrer les informations"
            :loading="loaders[LoadersIds.Submit]"
            class="p-mr-2 p-mb-2"
          />
          <!--<Button
            v-if="isSubscriptionRejected(currentSubscription)"
            v-bind:style="{ width: '25%' }"
            @click="update(1)"
            label="Resoumettre l'inscription"
            class="p-mr-2 p-mb-2"
            :loading="loading[1]"
          />-->
          <Button
            v-show="canPressNext"
            v-bind:style="{ width: '25%' }"
            @click="handleNextStep"
            label="Suivant"
            class="p-mr-2 p-mb-2"
            :loading="loaders[LoadersIds.Next]"
          />
          <!----->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Http from '@/services/http-v2.service';
import LoggedUserMixin from '@/mixins/logged-user';
import FormValidationMixin from '@/mixins/form-validation';
import { useVuelidate } from '@vuelidate/core';
import { required, minLength, numeric } from 'vuelidate/lib/validators';
import { BackendUrls } from '@/services/urlsConfigs';

export default {
  mixins: [LoggedUserMixin, FormValidationMixin],
  setup: () => ({ v$: useVuelidate() }),

  data() {
    return {
      currentSubscription: null,
      payload: {
        email: null,
        firstName: null,
        lastName: null,
        mobile: null,
        phone: null,
        positionDescription: null,
      },
      loaders: [false, false, false],
      LoadersIds: {
        Back: 0,
        Submit: 1,
        Next: 2,
      },
      loaderId: null,
      submitted: false,
      loggedUser: null,
      token: null,
    };
  },

  validations: {
    payload: {
      firstName: {
        required,
        minLength: minLength(2),
      },
      lastName: {
        required,
        minLength: minLength(2),
      },
      mobile: {
        required,
        minLength: minLength(2),
      },
      phone: {
        numeric,
      },
      positionDescription: {
        required,
        minLength: minLength(2),
      },
    },
  },

  mounted() {
    this.token = localStorage.getItem('token');
    if (this.token) {
      Http.get('/v1/users/getProfile', { headers: { Authorization: `Bearer ${this.token}` } })
        .then(this.handleFetchProfileSucceed)
        .catch(this.handleFetchProfileFailed);
    } else {
      this.$router.push('/login');
    }
  },
  methods: {
    canPressSubmit() {
      return (
        this.hasNotStarted(this.currentSubscription) ||
        this.isFillingUserForm(this.currentSubscription) ||
        this.isFillingEnterpriseForm(this.currentSubscription) ||
        this.isSubscriptionRejected(this.currentSubscription)
      );
    },

    canPressNext() {
      return this.canPressSubmit();
    },

    checkPoint() {
      this.submitted = true;
      if (this.getForm().$invalid) return false;
      return true;
    },

    handleFetchProfileSucceed(response) {
      this.loggedUser = response.data;
      this.payload = {
        email: this.loggedUser.email,
        firstName: this.loggedUser.firstName,
        lastName: this.loggedUser.lastName,
        mobile: this.loggedUser.mobile,
        phone: this.loggedUser.phone,
        positionDescription: this.loggedUser.positionDescription,
      };
      this.currentSubscription = this.getCurrentSubscription(this.loggedUser);
    },

    handleFetchProfileFailed(error) {
      console.log('ERROR DASHBOARD => ' + error);
    },

    handleSubmit() {
      const canSubmit = this.checkPoint();
      if (!canSubmit) return;
      this.loaders[this.LoadersIds.Submit] = true;
      this.updateUserRequest();
    },

    handleReSubmission() {},

    handlePreviousStep() {
      this.loaders[this.LoadersIds.Back] = true;
      this.$router.push('/');
    },

    handleNextStep() {
      const canSubmit = this.checkPoint();
      if (!canSubmit) return;
      this.loaders[this.LoadersIds.Next] = true;
      this.updateUserRequest();
      this.$router.push('/menu/seat');
    },

    async updateUserRequest() {
      try {
        const response = await Http.post(BackendUrls.SubscriptionUpdateUserUrl(), { ...this.payload });
        this.updateUserRequestSucceed(response);
      } catch (error) {
        this.updateUserRequestFailed(error);
      }
    },

    updateUserRequestSucceed() {
      this.loaders[this.LoadersIds.Submit] = false;
      this.loaders[this.LoadersIds.Next] = false;

      this.$toast.add({
        severity: 'success',
        summary: 'Accomplie.',
        detail: 'Les informations ont été enregistées avec succès.',
        life: 7000,
      });
    },

    updateUserRequestFailed(error) {
      this.loaders[this.LoadersIds.Submit] = false;
      this.loaders[this.LoadersIds.Next] = false;
      this.$toast.add({
        severity: 'error',
        summary: "Echec de l'inscription.",
        detail:
          error?.response?.data?.message ||
          'Une erreur est survenue, veuillez recommencer ou contacter votre administrateur.',
        life: 7000,
      });
    },
  },
};
</script>

<style>
.text-red {
  color: #db3c30;
}
.no-arrow {
  -moz-appearance: textfield;
}
.no-arrow::-webkit-inner-spin-button {
  display: none;
}
.no-arrow::-webkit-outer-spin-button,
.no-arrow::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
